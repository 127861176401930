

<template>

    <div v-if="item">
        <div class="flex justify-center">
            <img :src="wineImage(item)" class="oeno_portfolio_wineimage_big" onerror="this.style.display='none'" />
        </div>
        <div class="col-span-3 mt-10" v-if="item">
            <span class="text-3xl font-bold oeno_portfolio_title" v-if="item.wines">{{
                    item.wines.name
            }}</span>
            <span class="text-3xl font-bold oeno_portfolio_title" v-if="item.id">{{
                    item.name
            }}</span>
            
            <br />
            <span class="text-xl font-medium font-Montserrat">{{
                    winary(item)
            }}</span><br />
        </div>
    </div>
</template>
   
<script>


export default {
    name: 'WineDetailLeftSummary',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
    },
    methods: {


        wineImage(wine) {
            var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;

            if (wine.wines) {
                if (wine.wines.images) {
                    if (wine.wines.images[0]) {
                        wineimageurl =
                            process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
                    }
                }
            }

                
            if (wine) {

                if (wine.image) {
                        wineimageurl =
                            process.env.VUE_APP_WINE_IMAGE_PATH + wine.image;
                }
            }            

            return wineimageurl;
        },
        winary(wine) {
            var winary = "n/a";
            var vintage = "";
            var size = "";

            console.log(wine);

            if (wine.wines) {
                if (wine.wines.winery) {
                    vintage = wine.wines.vintage;
                    if (wine.wines.winery.name) {
                        winary = wine.wines.winery.name;
                    }
                    if (wine.wines.size.name) {
                        size = wine.wines.size.name;
                    }
                }
            }

            if (wine) {
                if (wine.id) {
                    vintage = wine.vintage;
                    winary = wine.winery;
                    size = wine.size;
                }
            }


            winary = winary + " " + vintage;

            if (size != "") {
                winary = winary + " " + size + "ML";
            }

            return winary;
        },


    }
}
</script>
