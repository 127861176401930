<template>
  <div class="ml-10 mr-10">
    <client-menu-left
      class="hidden sm:block mb-10"
      :open="menuOpen"
      v-on:openclick="menuOpenSwitch()"
    />

    <div v-if="!menuOpen">
      <div v-if="showWine == true">
        <WineDetailLeftSummary :item="wine" class="mb-20"/>

      </div>
      <div v-if="showPie == true">
        <div
          v-for="total in getTotals"
          v-bind:key="total.currency"
          class="flex"
        >
          <div class="">
            <span class="oeno_portfolio_total_total">{{
              currencyFormatter(total.total)
            }}</span
            ><br clear="all" />
            <span
              :class="[
                total.changePrice >= 0
                  ? 'oeno_portfolio_price_up'
                  : 'oeno_portfolio_price_down',
              ]"
              >{{ getChangeTotal(total) }}</span
            >
          </div>

          <div class="">
            <span class="oeno_portfolio_total_currency">{{
              total.currency
            }}</span>
          </div>
        </div>

        <PieChartPlotly
          class="sm:w-56 w-36 justify-self-center h-56 mt-10"
          :pieData="getPortfolioWithPieChartDatav2()"
        />

        <p class="oeno_portfolio_title mt-10">Breakdown</p>

        <div
          class="rows-span-3 space-y-1 font-Montserrat font-semibold pr-3"
          v-for="(item, index) in pieChartReturnData()"
          v-bind:key="index"
        >
          <div class="flex justify-between">
            <p class="breakdown-holder-text w-3/4">{{ index }}</p>
            <p class="breakdown-holder-text">{{ item.toFixed(1) }}%</p>
          </div>
        </div>

        <button
          @click="viewbroker()"
          class="w-64 h-10 mt-10 oeno_button_black zindex-10"
          v-if="showBrokerButton == true"
        >
          Contact Your Account Manager</button
        ><br /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import ClientMenuLeft from "@/components/pageparts/ClientMenuLeft.vue";
import PieChartPlotly from "@/components/PieChartPlotly.vue";
import WineDetailLeftSummary from "@/components/wine/WineDetailLeftSummary.vue";
import moment from "moment";

export default {
  name: "LeftSideStrip",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    showBrokerButton: {
      type: Boolean,
      default: true,
    },
    showPie: {
      type: Boolean,
      default: true,
    },
    showWine: {
      type: Boolean,
      default: false,
    },
    wine: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      menuOpen: false,
      pi: [],
      colors: [
        "#F27C00",
        "#FEA400",
        "#91B508",
        "#aa57ea",
        "#ce7805",
        "#b384d2",
        "#ffeacc",
        "#ffcc7b",
        "#ce7805",
      ],
    };
  },
  components: {
    ClientMenuLeft,
    PieChartPlotly,
    WineDetailLeftSummary
  },
  computed: {
    getTotals() {
      const values = [];

      if (this.$store.state.portfolio) {
        this.$store.state.portfolio.forEach((investment) => {
          investment.items.forEach((item) => {
            var itemPrice = this.getValuesForItem(investment, item);

            var pos = -1;
            for (let index = 0; index < values.length; index++) {
              if (values[index].currency == itemPrice.currency) {
                pos = index;
              }
            }

            if (pos > -1) {
              values[pos].total =
                values[pos].total + parseFloat(itemPrice.total);
              values[pos].changePrice =
                values[pos].changePrice + parseFloat(itemPrice.changePrice);
            } else {
              values.push({
                currency: itemPrice.currency,
                changePrice: parseFloat(itemPrice.changePrice),
                total: itemPrice.total,
              });
            }
          });
        });
      }
      return values;
    },
  },
  methods: {
    menuOpenSwitch() {
      this.menuOpen = !this.menuOpen;
    },
    pieChartReturnData() {
      let uniqueNames = {};
      let totalValue = 0;
      let temp = this.getPortfolioWithPieChartDatav2();
      temp.forEach((obj) => {
        totalValue += obj.price;
        if (!uniqueNames[obj.labels]) {
          uniqueNames[obj.labels] = obj.price;
        } else {
          uniqueNames[obj.labels] += obj.price;
        }
      });

      let namePercentage = {};
      for (let name in uniqueNames) {
        namePercentage[name] = (uniqueNames[name] / totalValue) * 100;
      }
      return namePercentage;
    },
    getPortfolioWithPieChartDatav2() {
      let retValArray = [];
      let colorArray = [];
      let pos = 0;
      let coloPos = -1;

      this.$store.state.portfolio.forEach((investment) => {
        investment.items.forEach((item) => {
          pos = pos + 1;

          coloPos = colorArray.indexOf(item.wines.winery.region.name);
          if (coloPos == -1) {
            colorArray.push(item.wines.winery.region.name);
            coloPos = colorArray.length - 1;
          }

          retValArray.push({
            investment: investment,
            item: item,
            color: this.colors[coloPos],
            labels: item.wines.name,
            price: Math.round(this.getPrice(investment, item)),
          });
        });
      });
      return retValArray;
    },

    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }

      var buytotal =
        wine.quantity * wine.wines.units_per_case * wine.bottle_price;

      var total =
        parseInt(
          parseFloat(
            wine.quantity *
              wine.wines.units_per_case *
              wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

      if (wine.livePrices.data["price-average"] == 0) {
        total = buytotal;
      }

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc =
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) *
        100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
      };
    },

    getChangeTotal(total) {
      var changePerc =
        (total.changePrice / (total.total - total.changePrice)) * 100;

      return (
        this.currencyFormatter(total.changePrice) +
        "(" +
        changePerc.toFixed(2) +
        "%)"
      );
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total;
    },
    viewbroker() {
      this.$router.push({ name: "page-broker-detail" });
    },
  },
};
</script>
