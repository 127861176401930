

<template>

    <div style="width:100%;z-index:1">

        <div  v-if="!open"
            class="bg-black rounded-full  focus:ring-4 focus:outline-none  oeno_portfolio_title_small text-sm px-4 text-center inline-flex items-center h-10 oeno_button_black">
            <md-button style="color: white;" class="" v-on:click="$emit('openclick')">
                Account&nbsp;&#x2630;
            </md-button>
        </div>

        <div v-if="open" class="float-left">

            
                <div class="font-Montserrat text-slate-50 text-sm text-gray-900 dark:text-white">
                    <div class="oeno_portfolio_sub_title text-black truncate">{{ this.$store.state.user.email }}</div>
                </div><br/>
                <router-link to="/portfolio" 
                        class="left-menu-links " style="z-index:999999999999">Your Portfolio</router-link><br/><br/><br/>
                <router-link to="/transactions" style="color: black;"
                        class="left-menu-links mb-10">Orders / Tranactions</router-link><br/><br/><br/>
                <router-link to="/profile" style="color: black;"
                        class="left-menu-links mb-10">Profile</router-link><br/><br/><br/>
                <a @click="logout()"  style="z-index:999999999999"
                        class="left-menu-links mb-10">Logout</a><br/><br/>
            
        </div>

    </div>

</template>
   
<script>


export default {
    name: 'ClientMenuLeft',
    props: {
        open: {
            type: Boolean,
            default: false
        }        
    },    
    methods: {
        logout() {
            this.$store.dispatch('login', { token: '' })
            this.$router.push('/login')
            this.$store.dispatch('storePortfolioData', {});
        },
    }
}
</script>
