<template>
    <div class="sm:grid grid-cols-5 sm:mt-40 mt-20   top-0 left-0 right-0 h-36 shadow-md hidden sm:block">

      <div 
        class=" h-full w-full resize rounded-md border-indigo-200 border-r-indigo-500 background-f4">

        <div class="grid ml-10 mt-10 ">
          <div class="mb-8 ">

            <div class="sm:justify-center float-right lg:float-left">
              <h1 class=" oeno_portfolio_title">{{ getUserName }}</h1>
              <h3 class="oeno_portfolio_title   ">
                {{ getUserReference }}<br /><br />
              </h3>

            </div>


          </div>



          </div>
      </div>
      <div style="background-color :#F4F4F4;" class=" h-36 w-auto col-span-4 hidden  sm:block">
        <div class="grid grid-cols-12 gap-6 mt-5">
          <h1 class="text-4xl font-bold ml-10 font-Montserrat mt-5 " :class="[ showbroker ? 'col-span-4' : 'col-span-8' ]">{{title}}<br /><br /></h1>
          <p class="font-Montserrat font-medium text-sm  col-span-5  mt-5" v-if="showbroker == true"><i>
              The prices shown are estimates and could change when you come to
              sell your wines.<br />
              Your broker : {{ getUserBroker }}
            </i> </p>
          <div class="grid justify-items-center cols-span-2 space-y-4 h-4/5 ">
            <button style="border-color: #000000" class="w-48 h-10 oeno_button_white" @click="buywines('oenohouse')" v-if="showaction == true">BUY
              TO
              CONSUME</button>
            <button class="w-72 h-10 oeno_button_black " @click="buywines('future')" v-if="showaction == true" >ADD
              TO INVESTMENT ACCOUNT</button>
            <button class="w-72 h-10 oeno_button_black mt-10" @click="$router.go(-1)"  v-if="showback == true">GO BACK</button>
            <button class="w-72 h-10 oeno_button_white mt-10" @click="$router.push({name: 'page-portfolio'})"  v-if="showportfolio == true">CHECK YOU PORTFOLIO</button>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
export default {
  name: "SecondaryNavbar",
  props: {
    title: {
      type: String,
      default: "",
    },
    showbroker: {
      type: Boolean,
      default: true,
    },
    showaction: {
      type: Boolean,
      default: true,
    },
    showback: {
      type: Boolean,
      default: false,
    },
    showportfolio: {
      type: Boolean,
      default: false,
    },
  },    
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    getUserBroker() {
      var broker = "";
      if (this.$store.state.user) {
        broker =
          this.$store.state.user.brokerName +
          " " +
          this.$store.state.user.brokerEmail;
      }
      return broker;
    },
  },
  methods: {
    buywines(type) {
      this.$store.dispatch("storeMarketData", type).then(() => {
        this.$router.push(
          {
            name: 'page--stock',
            params: { type: type },
          })
      });
    },
    sellwine(id, orderId) {
      this.$router.push({
        name: 'page--sell', params: { id: id, orderId: orderId }
      });
    },
  }
}
</script>

<style>

</style>