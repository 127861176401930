<template>
    <div style="" class=" sm:grid grid-cols-5 gap-4 pb-1 h-20 sm:h-40 bg-white navbar-bottom-border zindex-20 pagewidth-max">

        <div class="sm:grid grid-row-4 gap-1 justify-center">
            <div class="sm:row-start-3">
                <p style="" class=" font-semibold text-lg   inline-block align-bottom hidden  sm:block">
                    UK | EN</p>
            </div>
            <div class="relative text-gray-600 focus-within:text-gray-400 row-start-4 row-span-2 hidden  sm:block">

                <span class="absolute inset-y-0 left-0 flex items-center pl-2 mb-10">
                    <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6">
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                    </button>
                </span>
                <input type="search" name="q"
                    class="w-56 h-9 mb-3 rounded-full border border-black align-middle   text-"
                    autocomplete="off">

            </div>
        </div>
        <div class="vx-col   col-span-3 mt-5  ">
            <center>
                <img src="@/assets/logo.png" alt="logo" class="logo sm:mx-auto sm:inset-10 w-24 h-7 sm:w-60 sm:mt-10 sm:mb-10 " />
            </center>

        </div>

    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>