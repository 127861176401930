<template>
    <div class="   w-full  hidden  sm:block">

        <div class="text-white w-full h-96 bg-black   grid grid-cols-10   gap-7 ">

            <div class="col-span-3 bg-black  font-Montserrat font-medium  ">
                <div class="grid grid-rows-3  gap-3 h-full">
                    <div class="row-span-3 w-3/5   flex justify-between  place-items-center justify-self-center  ...">

                        <div class="space-y-4   ">

                            <div>
                                <h5>LONDON</h5>
                                <p> +44 (0) 207 846 3366</p>
                            </div>
                            <div>
                                <h5> TUSCANY</h5>
                                <p>+39 (0) 5771 460 030</p>
                            </div>
                            <div>
                                <h5>NEW YORK</h5>
                                <p> +1 (212) 220-6669</p>
                            </div>

                        </div>
                        <div class="space-y-4 mb-14  ">

                            <div>
                                <h5>MADRID</h5>
                                <p> +34 919 011 959</p>
                            </div>
                            <div>
                                <h5> LISBON</h5>
                                <p> +44 (0) 207 846 3366</p>
                            </div>
                        </div>

                    </div>
                    <div class=" w-2/3 row-span-3 flex justify-between justify-self-center ...">
                        <p><a href="https://www.facebook.com/oenoofficial" class="footer-link">Facebook</a></p>
                        <p><a href="https://www.instagram.com/oeno_official/" class="footer-link">Instagram</a></p>
                        <p><a href="https://www.linkedin.com/company/oenofuture-ltd-/" class="footer-link">Linkdin</a></p>
                    </div>
                    <div class="row-span-3 ..."></div>
                </div>
            </div>

            <div class="col-span-4 ">
                <div class="grid grid-rows-6  gap-1 h-full  ">
                    <div></div>

                    <div class="row-span-4 grid grid-row-3  text-center gap-2">

                        <p style="font-family: 'Crimson Pro', serif;"
                            class="justify-between justify-self-center place-self-center text-5xl ">SUBSCRIBE <i>to <br>
                                our</i>
                            NEWSLETTER</p>
                        <input
                            class=" w-3/4 h-12 rounded-full text-center  text-xl font-Montserrat text-2xl   font-normal justify-between justify-self-center place-self-center"
                            placeholder="e-mail">
                        <button
                            class=" w-40 h-14 rounded-full border-2 border-white  text-center  text-xl  justify-self-center font-Montserrat text-2xl   font-normal mt-8 ">Submit</button>

                    </div>
                    <div
                        class=" mt-6 w-2/3 row-span-3 flex justify-between  justify-self-center font-Montserrat  font-medium ...">
                        <p><a href="https://oenogroup.com/future/#!" class="footer-link">About Us</a></p>
                        <p><a href="https://oenogroup.com/future/#!" class="footer-link">Delivery & Returns</a></p>
                        <p><a href="https://oenogroup.com/future/#!" class="footer-link">FAQ</a></p>
                        <p><a href="https://oenogroup.com/" class="footer-link">Careers</a></p>
                        <p><a href="https://oenogroup.com/privacy-policy/" class="footer-link">Privacy Policy</a></p>
                    </div>


                </div>
            </div>
            <div class="col-span-3   font-Montserrat">

                <div class=" grid  grid-rows-5 grid-cols-2  gap-x-10  h-full   ">
                    <div></div>
                    <div class="row-start-2 row-span-4">
                        <img class="w-5/6 h-10 bg-black" src="@/assets/the_fine_wine_merchant_white.png">
                        <br>
                        <br>
                        <p style="font: normal normal normal 14px/19px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;" class="text-white ">
                            Company Reg No. 09637864 <br> VAT No. GB 234 3930 18 <br> AWRS Reg No. XPAW 000 0010 8283
                            <br> <a href="https://oenogroup.com/privacy-policy/" class="footer-link">Privacy Policy</a> <br> Right to withdraw
                        </p>
                        <!-- <br> -->
                        <br>
                        <!-- <br> -->
                        <p style="font: normal normal normal 10px/14px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;" class="">
                            © 2022 OenoFuture Ltd. All Rights Reserved.. <br>You must be 18 or over to order.
                        </p>

                    </div>

                    <div class=" row-start-3 row-span-3 ml-10 ">
                        <img class="w-12 bg-black " src="@/assets/tool.png">

                        <img class="w-12 bg-black " src="@/assets/apple-pay.png">

                        <img class="w-12 bg-black " src="@/assets/visa-2.png">

                        <img class="w-12 bg-black " src="@/assets/paypal.png">
                    </div>
                    <div>

                    </div>
                    <!-- <div class="  ">
                                    <img class="w-9  bg-white" src="@/assets/paypal.png">
                                </div> -->
                </div>





            </div>

        </div>
</div>
</template>

<script>
export default {
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
        }
    }
}
</script>

<style></style>